import PropTypes from 'prop-types';
import {branch, compose, getContext, renderNothing, withProps} from 'recompose';
import {FILTERED_CLIENT_TYPOLOGIES} from './SubscriptionTypeSelector';

const findSubscriptionTypeClientTypology = ({
                                              subscriptionType,
                                              subscriptionTypes,
                                            }) =>
  subscriptionTypes.find(_ => _.subscriptionType === subscriptionType)?.clientTypology;

const getSubscriptionType = ({
                               subscriptionType,
                               subscriptionTypes,
                             }) => {
  console.log('getSubscriptionType', subscriptionType, subscriptionTypes)
  if (
    !subscriptionType ||
    FILTERED_CLIENT_TYPOLOGIES.includes(findSubscriptionTypeClientTypology({
      subscriptionType,
      subscriptionTypes,
    }))
  ) {
    return '';
  }

  return subscriptionType;
};

export default (useUpdatedSubscriptionType) => compose(
  getContext(
    {
      currentSubscriptionType: PropTypes.string,
      subscriptionState: PropTypes.object,
      subscriptionTypes: PropTypes.array,
    },
  ),
  branch(
    ({subscriptionTypes}) => !subscriptionTypes,
    renderNothing,
  ),
  withProps(
    ({
       currentSubscriptionType,
       subscriptionState,
       subscriptionTypes,
     }) => ({
      subscriptionType: getSubscriptionType({
        subscriptionType: useUpdatedSubscriptionType ? subscriptionState?.type : currentSubscriptionType,
        subscriptionTypes,
      }),
    }),
  ),
);