import { SUBSCRIPTION_TYPE_GROUPS } from '../../../../../../../../utils/Subscription';
import {isN2OrHigher} from '../../../../../../../../utils/functions';
import {FILTERED_CLIENT_TYPOLOGIES, SUBSCRIPTION_OPTIONS} from './SubscriptionTypeSelector';

export const isSameClientTypology = (classification, subscriptionType) => subscriptionType.clientTypology === classification.clientTypology.value;

export const isSameGroup = (classification, subscriptionType) => subscriptionType.group === classification.group.value;

export const subscriptionOptionsMatchesClassificationValue = (subscriptionType, classificationValue, possibleValues) => {
  if (classificationValue.value === null) { // Return true if filter is not needed
    return !classificationValue.needToBeFilled;
  } else if (classificationValue.value === undefined) { // Return true if subscription type does not include any value of this option
    return !possibleValues.some(possibleValue => subscriptionType.options.includes(possibleValue));
  } else { // Return true if subscription type includes this option value
    return !!subscriptionType.options.includes(classificationValue.value);
  }
};

const getNextValue = bool => ({
  needToBeFilled: bool,
  value: null,
});

export const getUpdatedClassification = ({
                                           classification,
                                           hasBreakageInsuranceOptions,
                                           hasBreakageWarrantyOptions,
                                           hasGroups,
                                           hasPackOptions,
                                           hasSimOptions,
                                           option,
                                           property,
                                         }) => {
  switch (property) {
    case SUBSCRIPTION_OPTIONS.clientTypology.name:
      return {
        breakageInsurance: getNextValue(!hasGroups && !hasPackOptions && hasBreakageInsuranceOptions),
        breakageWarranty: getNextValue(!hasGroups && !hasPackOptions && hasBreakageWarrantyOptions),
        clientTypology: {
          ...classification.clientTypology,
          value: option,
        },
        group: getNextValue(hasGroups),
        pack: getNextValue(!hasGroups && hasPackOptions),
        sim: getNextValue(!hasGroups && !hasPackOptions && !hasBreakageInsuranceOptions && hasSimOptions),
      };

    case SUBSCRIPTION_OPTIONS.group.name:
      return {
        ...classification,
        breakageInsurance: getNextValue(!hasPackOptions && hasBreakageInsuranceOptions),
        breakageWarranty: getNextValue(!hasPackOptions && hasBreakageWarrantyOptions),
        group: {
          ...classification.group,
          value: option,
        },
        pack: getNextValue(hasPackOptions),
        sim: getNextValue(!hasPackOptions && !hasBreakageInsuranceOptions && hasSimOptions),
      };

    case SUBSCRIPTION_OPTIONS.pack.name:
      return {
        ...classification,
        breakageInsurance: getNextValue(hasBreakageInsuranceOptions),
        breakageWarranty: getNextValue(hasBreakageWarrantyOptions),
        pack: {
          ...classification.pack,
          value: option,
        },
        sim: getNextValue(!hasBreakageInsuranceOptions && hasSimOptions),
      };
      
      case SUBSCRIPTION_OPTIONS.breakageWarranty.name:
        console.log('SUBSCRIPTION_OPTIONS.breakageWarranty.name', classification, option)
        return {
          ...classification,
          breakageInsurance: getNextValue(hasBreakageInsuranceOptions),
          breakageWarranty: {
            ...classification.breakageWarranty,
            value: option,
          },
          sim: getNextValue(hasSimOptions),
        };  

    default:
      return {
        ...classification,
        sim: {
          ...classification.sim,
          value: option,
        },
      };
  }
};

const findAndSortValues = (subscriptionTypes, findFunction, orderedValues, firstValue) =>
  Array
    .from(
      new Set(subscriptionTypes.map(findFunction)),
    )
    .sort((a, b) => {
      if (a === firstValue) {
        return -1;
      } else if (b === firstValue) {
        return 1;
      } else {
        return orderedValues.indexOf(a) < orderedValues.indexOf(b) ? -1 : 1;
      }
    });

const getFindOptionValueFunction = (optionsPossibleValues) => (objWithOptions) => objWithOptions?.options?.find(option => optionsPossibleValues.includes(option));

const findOptionValues = (subscriptionTypes, optionsPossibleValues, filterFunction) =>
  findAndSortValues(
    subscriptionTypes.filter(filterFunction),
    getFindOptionValueFunction(optionsPossibleValues),
    optionsPossibleValues,
  );

export const getExcludeNonNeededGroupsFunction = (currentSubscriptionTypeObject, checkUserRights) => (subscriptionType) => {
  if (checkUserRights && isN2OrHigher && [
    SUBSCRIPTION_TYPE_GROUPS.DEMO,
    SUBSCRIPTION_TYPE_GROUPS.ONLY_SIM,
    SUBSCRIPTION_TYPE_GROUPS.B2B_PREPAYE,
    SUBSCRIPTION_TYPE_GROUPS.LOCATION,
    SUBSCRIPTION_TYPE_GROUPS.PRET
  ].includes(subscriptionType?.group)) {
    return true;
  }
  // Offre Legacy => on peut plus la sélectionner
  if (subscriptionType.group === SUBSCRIPTION_TYPE_GROUPS.LANCEMENT) {
    return false;
  }
  // Offre actuelle Equilibre => on ne peut sélectionner qu'une variante d'Equilibre
  else if ([SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE, SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_FIDELITE, SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_PREPAYE].includes(currentSubscriptionTypeObject?.group)) {
    return [SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE, SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_FIDELITE, SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_PREPAYE].includes(subscriptionType.group);
  }
  // Offre actuelle Sérénité ou Sérénité fidélité => on ne peut sélectionner qu'une variante de la même offre
  else if ([SUBSCRIPTION_TYPE_GROUPS.SERENITE, SUBSCRIPTION_TYPE_GROUPS.SERENITE_FIDELITE].includes(currentSubscriptionTypeObject?.group)) {
    return [
      SUBSCRIPTION_TYPE_GROUPS.SERENITE,
      // SUBSCRIPTION_TYPE_GROUPS.SERENITE_FIDELITE, TODO : Reactivate when needed CAN-521
    ].includes(subscriptionType.group);
  }
  // Offre actuelle Liberté par prélèvement mensuel => on ne peut sélectionner qu'une variante de la même offre
  else if (SUBSCRIPTION_TYPE_GROUPS.LIBERTE === currentSubscriptionTypeObject?.group) {
    return subscriptionType.group === currentSubscriptionTypeObject?.group;
  }
  // Offre actuelle Liberté prépayée => on ne peut sélectionner qu'une variante de Liberté prépayé ou de Liberté mensuel
  else if (SUBSCRIPTION_TYPE_GROUPS.LIBERTE_PREPAYE === currentSubscriptionTypeObject?.group) {
    return [SUBSCRIPTION_TYPE_GROUPS.LIBERTE, SUBSCRIPTION_TYPE_GROUPS.LIBERTE_PREPAYE].includes(subscriptionType.group);
  }
  // Offre actuelle classique => offre classique ou Equilibre
  else if ([
    SUBSCRIPTION_TYPE_GROUPS.CLASSIQUE_MENSUEL,
    SUBSCRIPTION_TYPE_GROUPS.CLASSIQUE_PREPAYE,
    SUBSCRIPTION_TYPE_GROUPS.RENOUV,
  ].includes(currentSubscriptionTypeObject?.group)) {
    return [
      SUBSCRIPTION_TYPE_GROUPS.CLASSIQUE_MENSUEL,
      SUBSCRIPTION_TYPE_GROUPS.CLASSIQUE_PREPAYE,
      SUBSCRIPTION_TYPE_GROUPS.RENOUV,
      SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE,
      SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_FIDELITE,
      SUBSCRIPTION_TYPE_GROUPS.EQUILIBRE_PREPAYE,
    ].includes(subscriptionType.group);
  }
  // Pour le reste (offres de démo, location, prêt...) on laisse la possibilité de changer mais pas vers des offres Equilibre, Liberté ni Sérénité
  else {
    return ![
      SUBSCRIPTION_TYPE_GROUPS.LANCEMENT,
      SUBSCRIPTION_TYPE_GROUPS.LIBERTE,
      SUBSCRIPTION_TYPE_GROUPS.LIBERTE_PREPAYE,
      SUBSCRIPTION_TYPE_GROUPS.SERENITE_FIDELITE, // TODO : Remove when needed CAN-521
    ].includes(subscriptionType.group);
  }
};

export const getClassificationProps = ({
                                         classification,
                                         currentSubscriptionType,
                                         subscriptionTypes,
                                         checkUserRights = true
                                       }) => {
  const currentSubscriptionTypeObject = subscriptionTypes.find(
    _ =>
      _.subscriptionType === currentSubscriptionType &&
      !FILTERED_CLIENT_TYPOLOGIES.includes(_.clientTypology),
  );
  const excludeNonNeededGroups = getExcludeNonNeededGroupsFunction(currentSubscriptionTypeObject, checkUserRights);
  const filteredSubscriptionTypes = subscriptionTypes.filter(excludeNonNeededGroups);

  const {
    isInitialLoading,
    ...currentClassificationValues
  } = getCurrentClassificationValues({
    classification,
    currentSubscriptionTypeObject,
  });

  const clientTypologies =
    findAndSortValues(
      filteredSubscriptionTypes,
      _ => _.clientTypology,
      SUBSCRIPTION_OPTIONS.clientTypology.values,
      undefined,
    )
      .filter(
        typology => !FILTERED_CLIENT_TYPOLOGIES.includes(typology),
      );

  const defaultClassificationWithClientTypology = {
    ...currentClassificationValues,
    clientTypology: {
      needToBeFilled: true,
      value: currentClassificationValues.clientTypology,
    },
  };

  const groups = findAndSortValues(
    filteredSubscriptionTypes.filter(
      _ =>
        _.clientTypology === defaultClassificationWithClientTypology.clientTypology.value &&
        excludeNonNeededGroups(_),
    ),
    _ => _.group,
    SUBSCRIPTION_OPTIONS.group.values,
    undefined,
  );

  const defaultClassificationWithGroup = {
    ...defaultClassificationWithClientTypology,
    group: {
      needToBeFilled: hasOptions(groups),
      value: currentClassificationValues.group,
    },
  };

  const packOptions = findOptionValues(
    filteredSubscriptionTypes,
    SUBSCRIPTION_OPTIONS.pack.values,
    subscriptionType =>
      isSameClientTypology(defaultClassificationWithGroup, subscriptionType) &&
      isSameGroup(defaultClassificationWithGroup, subscriptionType),
  );

  const defaultClassificationWithPack = {
    ...defaultClassificationWithGroup,
    pack: {
      needToBeFilled: hasOptions(packOptions),
      value: currentClassificationValues.pack,
    },
  };

  const breakageInsuranceOptions = findOptionValues(
    filteredSubscriptionTypes,
    SUBSCRIPTION_OPTIONS.breakageInsurance.values,
    subscriptionType =>
      isSameClientTypology(defaultClassificationWithPack, subscriptionType) &&
      isSameGroup(defaultClassificationWithPack, subscriptionType) &&
      subscriptionOptionsMatchesClassificationValue(subscriptionType, defaultClassificationWithPack.pack, SUBSCRIPTION_OPTIONS.pack.values),
  );

  const breakageWarrantyOptions = findOptionValues(
    filteredSubscriptionTypes,
    SUBSCRIPTION_OPTIONS.breakageWarranty.values,
    subscriptionType => {
      console.log('breakageWarrantyOptions', breakageWarrantyOptions, subscriptionType, defaultClassificationWithPack.pack, SUBSCRIPTION_OPTIONS.pack.values);
      return isSameClientTypology(defaultClassificationWithPack, subscriptionType) &&
      isSameGroup(defaultClassificationWithPack, subscriptionType) &&
      subscriptionOptionsMatchesClassificationValue(subscriptionType, defaultClassificationWithPack.pack, SUBSCRIPTION_OPTIONS.pack.values)},
  );
  

  const defaultClassificationWithBreakageInsurance = {
    ...defaultClassificationWithPack,
    breakageInsurance: {
      needToBeFilled: false,
      value: currentClassificationValues.breakageInsurance,
    },
    breakageWarranty: {
      needToBeFilled: hasOptions(breakageWarrantyOptions),
      value: currentClassificationValues.breakageWarranty,
    },
  };

  const simOptions = findOptionValues(
    filteredSubscriptionTypes,
    SUBSCRIPTION_OPTIONS.sim.values,
    subscriptionType => (
      isSameClientTypology(defaultClassificationWithBreakageInsurance, subscriptionType) &&
      isSameGroup(defaultClassificationWithBreakageInsurance, subscriptionType) &&
      subscriptionOptionsMatchesClassificationValue(subscriptionType, defaultClassificationWithBreakageInsurance.pack, SUBSCRIPTION_OPTIONS.pack.values) &&
      (subscriptionOptionsMatchesClassificationValue(subscriptionType, defaultClassificationWithBreakageInsurance.breakageInsurance, SUBSCRIPTION_OPTIONS.breakageInsurance.values) ||
        subscriptionOptionsMatchesClassificationValue(subscriptionType, defaultClassificationWithBreakageInsurance.breakageWarranty, SUBSCRIPTION_OPTIONS.breakageWarranty.values))
    ),
  );

  const defaultClassificationWithSim = {
    ...defaultClassificationWithBreakageInsurance,
    sim: {
      needToBeFilled: hasOptions(simOptions),
      value: currentClassificationValues.sim,
    },
  };

  return {
    breakageInsuranceOptions,
    breakageWarrantyOptions,
    classification: defaultClassificationWithSim,
    clientTypologies,
    groups,
    packOptions,
    simOptions,
  };
};

export const hasOptions = options => {
  if (options.length < 1) {
    return false;
  } else if (options.length === 1) {
    return options[0] !== undefined;
  }

  return true;
};

export const resetFieldValue = {
  needToBeFilled: false,
  value: null,
};

export const getUpdatedClassificationWithResetFields = (classification, property, option) => {
  if (property === SUBSCRIPTION_OPTIONS.clientTypology.name) {
    return {
      breakageInsurance: resetFieldValue,
      breakageWarranty: resetFieldValue,
      clientTypology: {
        needToBeFilled: true,
        value: option,
      },
      group: resetFieldValue,
      pack: resetFieldValue,
      sim: resetFieldValue,
    };
  } else if (property === SUBSCRIPTION_OPTIONS.group.name) {
    return {
      ...classification,
      breakageInsurance: resetFieldValue,
      breakageWarranty: resetFieldValue,
      group: {
        needToBeFilled: true,
        value: option,
      },
      pack: resetFieldValue,
      sim: resetFieldValue,
    };
  } else if (property === SUBSCRIPTION_OPTIONS.pack.name) {
    return {
      ...classification,
      breakageInsurance: resetFieldValue,
      breakageWarranty: resetFieldValue,
      pack: {
        needToBeFilled: true,
        value: option,
      },
      sim: resetFieldValue,
    };
  } else if (property === SUBSCRIPTION_OPTIONS.sim.name) {
    return {
      ...classification,
      breakageInsurance: resetFieldValue,
      breakageWarranty: resetFieldValue,
      sim: {
        needToBeFilled: true,
        value: option,
      },
    };
  } else {
    return {
      ...classification,
      breakageInsurance: {
        needToBeFilled: false,
        value: option,
      },
      breakageWarranty: {
        needToBeFilled: true,
        value: option,
      },
    };
  }
};

const getCurrentClassificationValues = ({
                                          classification,
                                          currentSubscriptionTypeObject,
                                        }) => {

  if (classification.clientTypology.value || !currentSubscriptionTypeObject) {
    return {
      isInitialLoading: false,
      breakageInsurance: classification.breakageInsurance.value,
      breakageWarranty: classification.breakageWarranty.value,
      clientTypology: classification.clientTypology.value,
      group: classification.group.value,
      pack: classification.pack.value,
      sim: classification.sim.value,
    };
  } else {
    return {
      isInitialLoading: true,
      breakageInsurance: getFindOptionValueFunction(SUBSCRIPTION_OPTIONS.breakageInsurance.values)(currentSubscriptionTypeObject),
      breakageWarranty: getFindOptionValueFunction(SUBSCRIPTION_OPTIONS.breakageWarranty.values)(currentSubscriptionTypeObject),
      clientTypology: currentSubscriptionTypeObject.clientTypology,
      group: currentSubscriptionTypeObject.group,
      pack: getFindOptionValueFunction(SUBSCRIPTION_OPTIONS.pack.values)(currentSubscriptionTypeObject),
      sim: getFindOptionValueFunction(SUBSCRIPTION_OPTIONS.sim.values)(currentSubscriptionTypeObject),
    };
  }
};