import React, { Component } from 'react';
import { Button, FormControl, FormGroup, Glyphicon, Table } from 'react-bootstrap';

import {sortAlpha} from '../../utils/Array';
import { getTeamType } from '../../utils/functions';
import jsRoutes from '../../routes/routes';
import LoadingProgress from '../components/LoadingProgress';
import UltimatePagination from '../components/UltimatePagination';
import WarningModal from '../components/WarningModal';

import UserEdit from './UserManager/UserEdit';
import UserManagerTabEntry from './UserManager/UserManagerTabEntry';
import {connectMeAndTeamsAndConstantsContext} from '../../hoc/withMeAndTeamsAndConstants';

const ITEM_PER_PAGE = 10;
const GET_USERS = (successFn, errorFn) => {
    jsRoutes.controllers.backoffice.UserV2Controller.getUsers()
        .ajax({dataType: 'json', processData: false})
        .then(
            successFn,
            errorFn
        );
};
const ADD_USER = (user, successFn, errorFn ) => {
    jsRoutes.controllers.backoffice.UserV2Controller.addUser()
        .ajax({contentType: 'application/json', processData: false, data: JSON.stringify(user)})
        .then(
            successFn,
            errorFn
        );
};
const EDIT_USER = (user, successFn, errorFn ) => {
    jsRoutes.controllers.backoffice.UserV2Controller.updateUser(user.id)
        .ajax({contentType: 'application/json', processData: false, data: JSON.stringify(user)})
        .then(
            successFn,
            errorFn
        );
};
const DELETE_USER = (id, successFn, errorFn ) => {
    jsRoutes.controllers.backoffice.UserV2Controller.deleteUser(id)
        .ajax({dataType: 'json', processData: false})
        .then(
            successFn,
            errorFn
        );
};

class UserManager extends Component {

    constructor(props) {
        super(props);

        const properties = [{
                name: 'Identifiant',
                size: '23%',
            },{
                name: 'Nom',
                size: '23%',
            },{
                name: 'Prénom',
                size: '23%',
            },{
                name: 'Equipe',
                type: 'type',
                size: '10%',
            },
        ];

        this.state = {
            loading: false,
            currentUser: null,
            properties: properties,
            activePage: 1,
            entries:[],
            users:[],
            sort: {},
            filters: null,
            deleting: false,
            editing: false,
        };
    }

    componentDidMount() {
        this.getEntries();
    }

    getEntries() {
        this.setState({loading: true});
        const _this = this;

        GET_USERS(
            (users) =>
                _this.setState({
                    entries: _this.formatEntries(users),
                    users: users,
                    loading: false,
                    currentUser: null,
                }),
            () => _this.setState({loading: false})
        );
    }

    formatEntry(user) {
        return {
            _id: user.id,
            'Identifiant': user.identifier,
            'Nom': user.lastName,
            'Prénom': user.firstName,
            'Equipe': getTeamType(this.props.types, user.team),
        };
    }

    formatEntries(users) {
        const _this = this;
        return sortAlpha(users, _ => _.identifier).map(user => _this.formatEntry(user));
    }

    isFiltered(entry) {
        if (!this.state.filters)
            return true;
        for (var property in this.state.filters) {
            let filterValue = this.state.filters[property],
                revertFilter = filterValue  && filterValue.toString().indexOf('$') === 0,
                cleanedValue = revertFilter ? filterValue.substring(1) : filterValue,
                containsValue;

            if (property === 'Type' || property === 'Equipe'){
                containsValue = entry.hasOwnProperty(property) &&
                                entry[property]  &&
                                entry[property].toString() === cleanedValue;
            }else {
                containsValue = entry.hasOwnProperty(property) &&
                    entry[property]  &&
                    entry[property].toString().toLocaleLowerCase().indexOf(cleanedValue.toLocaleLowerCase()) !== -1;
            }

            if((containsValue && revertFilter) || (!containsValue && !revertFilter)){
                return false;
            }
        }
        return true;
    }

    toFilterSelectInput(property, list, labelFunction){
        return (
            <FormGroup className='small-control' bsSize='small'>
                <FormControl componentClass='select'
                             value={this.state.filters && this.state.filters[property.name] ? this.state.filters[property.name]: ''}
                             onChange={this.onFilterInputChange.bind(this, property.name)}
                             placeholder={property.name}>
                    <option value=''>---</option>
                    {
                        sortAlpha(Object.keys(list), _ => property.name === 'Type' ? labelFunction(_): labelFunction(list, _))
                            .map(type_value =>
                                <option key={type_value} value={type_value}>{property.name === 'Type' ? labelFunction(type_value): labelFunction(list, type_value)}</option>
                            )
                    }
                </FormControl>
            </FormGroup>
        );
    }

    toFilterInput(property){
        let filterInput;

        if(property.name === 'Equipe'){
            const userTeams = {};
            Object.values(this.props.types.type_team).forEach(value => userTeams[value] = value);
            filterInput = this.toFilterSelectInput(property, userTeams, (list, value) => list[value]);
        } else {
            filterInput = (
                <FormGroup className='small-control' bsSize='small'>
                    <FormControl type='text'
                                 value={this.state.filters && this.state.filters[property.name] ? this.state.filters[property.name]: ''}
                                 onChange={this.onFilterInputChange.bind(this, property.name)} />
                </FormGroup>
            );
        }

        return <th key={`property_filter_${property.name}`}>{filterInput}</th>;
    }

    onFilterInputChange(name, event) {
        if (name === '') {
            this.setState({filters: null, activePage: 1});
            return;
        }

        let filters = this.state.filters;
        if (!filters) {
            filters = [];
        }

        if (!event.target.value) {
            delete filters[name];
        } else {
            filters[name] = event.target.value;
        }

        this.setState({filters: filters, activePage: 1});
    }

    onEditEntryClick(entryId) {
        this.setState({ currentUser: entryId, editing: true });
    }

    onEditEntryCancel() {
        this.setState({ currentUser: null, editing: false });
    }

    onEditEntryConfirm(user) {
        const _this = this;
        this.setState({
            currentUser: null,
            editing: false
        });

        EDIT_USER(
            user,
            (users) =>
                _this.setState({
                    entries: _this.formatEntries(users),
                    users: users,
                    loading: false,
                    currentUser: null,
                }),
            () => _this.setState({loading: false})
        );
    }

    onDeleteEntryClick(entryId) {
        this.setState({ currentUser: entryId, deleting: true });
    }

    onDeleteEntryCancel() {
        this.setState({ currentUser: null, deleting: false });
    }

    onDeleteEntryConfirm() {
        const { currentUser } = this.state;
        const _this = this;
        this.setState({
            currentUser: null,
            loading: true,
            deleting: false,
        });

        DELETE_USER(
            currentUser,
            (users) =>
                _this.setState({
                    entries: _this.formatEntries(users),
                    users: users,
                    loading: false,
                    currentUser: null,
                }),
            () => _this.setState({loading: false})
        );
    }

    onAddEntryClick() {
        this.setState({ currentUser: null, editing: true });
    }

    onAddEntryConfirm(user) {
        const _this = this;
        this.setState({
            currentUser: null,
            loading: true,
            editing: false,
        });

        ADD_USER(
            user,
            (users) =>
                _this.setState({
                    entries: _this.formatEntries(users),
                    users: users,
                    loading: false,
                    currentUser: null,
                }),
            () => _this.setState({loading: false})
        );
    }

    onPageSelected(num) {
        this.setState({activePage: num});
    }

    getCurrentUser() {
        return this.state.users.find(user => user.id === this.state.currentUser);
    }

    getCurrentUserIdentifier() {
        const user = this.getCurrentUser();
        return user ? user.identifier : '';
    }

    render() {
        const _this = this;
        let userTable, clearButton, pageGroup;

        if (this.state.entries) {
            const filteredEntries = this.state.entries.filter(e => _this.isFiltered(e));

            clearButton = (
                <Button bsStyle='primary' bsSize='xsmall' onClick={this.onFilterInputChange.bind(_this, '')} disabled={this.state.loading && this.state.filters}>
                    <Glyphicon glyph='remove'/>&nbsp;Effacer les filtres
                </Button>
            );

            if(filteredEntries.length > 0) {
                const pageNumber = Math.ceil(filteredEntries.length / ITEM_PER_PAGE);
                pageGroup = (<UltimatePagination totalPages={pageNumber} currentPage={this.state.activePage} onChange={this.onPageSelected.bind(this)}/>);
            }

            const entries = filteredEntries.slice((_this.state.activePage - 1) * ITEM_PER_PAGE, (_this.state.activePage) * ITEM_PER_PAGE).map(entry =>
                <UserManagerTabEntry key={entry._id}
                                     properties={_this.state.properties} entry={entry}
                                     onEditEntryClick={_this.onEditEntryClick.bind(_this, entry._id)}
                                     onDeleteEntryClick={_this.onDeleteEntryClick.bind(_this, entry._id)} />
            );

            userTable = (
                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        {this.state.properties.map(property =>
                            <th style={{
                                width: property.size
                            }}
                                key={'property_header_'+property.name}>
                                {property.name}&nbsp;
                            </th>
                        )}
                        <th style={{ width: '10%' }}></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {this.state.properties.map(propertie => _this.toFilterInput(propertie))}
                        <th className='text-center'>{clearButton}</th>
                    </tr>
                    {entries}
                    </tbody>
                </Table>
            );
        }

        let modal;
        if(this.state.deleting) {
            modal = (
              <WarningModal
                show={this.state.deleting}
                title={'Suppression de l\'utilisateur'}
                message={`Confirmez-vous la suppression de l'utilisateur ${this.getCurrentUserIdentifier()} ?`}
                onHide={this.onDeleteEntryCancel}
                buttons={[
                    <Button key='confirm' bsStyle='primary' onClick={this.onDeleteEntryConfirm.bind(this)}>Confirmer</Button>,
                    <Button key='cancel' bsStyle='danger' onClick={this.onDeleteEntryCancel.bind(this)}>Annuler</Button>,
                ]}
              />
            );
        } else if(this.state.editing) {
            modal = (
              <UserEdit
                  show={this.state.editing}
                  userId={this.state.currentUser}
                  onSubmit={this.state.currentUser ? this.onEditEntryConfirm.bind(this) : this.onAddEntryConfirm.bind(this)}
                  onHide={this.onEditEntryCancel.bind(this)}
              />
            );
        }

        return (
            <div>
                {modal}
                <LoadingProgress show={this.state.loading}/>
                <div className='text-center'>
                    {pageGroup}
                </div>
                <div style={{ padding: 5 }}>
                    <Button bsStyle='primary' onClick={this.onAddEntryClick.bind(this)}>Ajouter un utilisateur</Button>
                </div>
                {userTable}
                <div className='text-center'>
                    {pageGroup}
                </div>
            </div>
        );
    }
}

export default connectMeAndTeamsAndConstantsContext(UserManager);