import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class UserManagerTabEntry extends Component {
    render() {
        const { entry } = this.props;

        return (
            <tr>
                <td>{entry['Identifiant']}</td>
                <td>{entry['Nom']}</td>
                <td>{entry['Prénom']}</td>
                <td>{entry['Equipe']}</td>
                <td className='text-center'>
                    <Button bsStyle='primary' bsSize='xsmall' onClick={this.props.onEditEntryClick}>
                        <span className='glyphicon glyphicon-edit' /> Editer
                    </Button>
                    &nbsp;
                    <Button bsStyle='danger' bsSize='xsmall' onClick={this.props.onDeleteEntryClick}>
                        <span className='glyphicon glyphicon-remove' /> Supprimer
                    </Button>
                </td>
            </tr>
        );
    }
}

export default UserManagerTabEntry;